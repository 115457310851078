import React from 'react';
import s from "./style.module.scss";
import { FormLabel } from '@mui/material';


export default function SelectData({data, handleData}) {
  return (
    <>
      <FormLabel htmlFor="data">Selecione uma data:</FormLabel>
      <input
        type="date"
        id="data"
        className={s.agendar__field}
        value={data}
        onChange={handleData}
      />
    </>
  )
}