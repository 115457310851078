import { Link, useResolvedPath } from "react-router-dom";
import { StyledAppBar } from './styles.js'
import { useEffect, useState } from "react";
import { Toolbar, Typography, Button, Box, Skeleton } from "@mui/material";
import checkIsLogged from "components/IsLogged";
import API from "config/configs";

export default function Header() {
  const [path, setPath] = useState([{ agendar: "active" }]);
  const urlPath = useResolvedPath().pathname;
  const [isLogado, setIsLogado] = useState(false);
  const [administrador, setAdministrador] = useState(false);
  const [tituloHeader, setTituloHeader] = useState("");
  const [corTema, setCorTema] = useState("");
  const CPFAdministrador = "lksribeiro2014@gmail.com";

  const logout = (e) => {
    setPath([]);

    if (e.target.name === "/sair") {
      setPath([{ sair: "active" }]);
      localStorage.removeItem("isLogged");
      setIsLogado(false);
      setAdministrador(false);
      window.location.reload();
    }
  };

  const getServicos = async () => {
    await fetch(`${API}/configuracoes/lista`)
      .then((res) => res.json())
      .then((config) => {
        console.log('config', config)
        if (config.length) {
          setTituloHeader(config[0].tituloHeader);
          setCorTema(config[0].corTema);
          document
            .getElementsByTagName("body")[0]
            .setAttribute("tema", config[0].corTema);

          if (config[0].tituloHeader === null) setTituloHeader("Agenda");
          if (config[0].corTema === null) {
            setCorTema("#1e88e5");
            document
              .getElementsByTagName("body")[0]
              .setAttribute("tema", "#1e88e5");
          }
        } else {
          setCorTema("#1e88e5");
          document
            .getElementsByTagName("body")[0]
            .setAttribute("tema", "#1e88e5");
          setTituloHeader("Agenda");
        }
      });
  };

  const setRouteActive = (e) => {
    setPath([]);

    if (e.target.name === "/agendar") setPath([{ agendar: "active" }]);
    if (e.target.name === "/servicos") setPath([{ servicos: "active" }]);
    if (e.target.name === "/meus-agendamentos")
      setPath([{ meusAgendamentos: "active" }]);
    if (e.target.name === "/minhas-configuracoes")
      setPath([{ meusHorarios: "active" }]);
    if (e.target.name === "/login") setPath([{ login: "active" }]);
    if (e.target.name === "/funcionarios")
      setPath([{ funcionarios: "active" }]);
  };

  useEffect(() => {
    getServicos();

    if (urlPath === "/") setPath([{ agendar: "active" }]);
    if (urlPath === "/login") setPath([{ login: "active" }]);
    if (urlPath === "/meus-agendamentos")
      setPath([{ meusAgendamentos: "active" }]);
    if (urlPath === "/servicos") setPath([{ servicos: "active" }]);
    if (urlPath === "/minhas-configuracoes")
      setPath([{ meusHorarios: "active" }]);
    if (urlPath === "/funcionarios") setPath([{ funcionarios: "active" }]);

    checkIsLogged().then((res) => {
      let isLogged = localStorage.getItem("isLogged");

      if (isLogged !== "" && isLogged !== null) {
        isLogged = JSON.parse(isLogged);

        res.map((login) => {
          if (login.usuario === CPFAdministrador) {
            setAdministrador(true);
          }

          if (
            login.usuario === isLogged.login &&
            login.senha === isLogged.senha
          ) {
            setIsLogado(true);
          }
        });
      }
    });
  }, [urlPath]);

  console.log('corrrtemaa', corTema)

  return (
    corTema ? (
      <StyledAppBar setTheme={corTema} position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            {tituloHeader && (
              <Typography variant="h6" component="h1">
                {tituloHeader}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              color="inherit"
              component={Link}
              to="/"
              name="/agendar"
              onClick={setRouteActive}
            >
              Agendar
            </Button>
            <Button
              color="inherit"
              component={Link}
              to="/servicos"
              name="/servicos"
              onClick={setRouteActive}
            >
              Serviços
            </Button>
            {administrador && (
              <Button
                color="inherit"
                component={Link}
                to="/funcionarios"
                name="/funcionarios"
                onClick={setRouteActive}
              >
                Funcionários
              </Button>
            )}
            <Button
              color="inherit"
              component={Link}
              to="/meus-agendamentos"
              name="/meus-agendamentos"
              onClick={setRouteActive}
            >
              Meus Agendamentos
            </Button>
            {administrador && (
              <Button
                color="inherit"
                component={Link}
                to="/minhas-configuracoes"
                name="/minhas-configuracoes"
                onClick={setRouteActive}
              >
                Minhas configurações
              </Button>
            )}
            {!isLogado ? (
              <Button
                color="inherit"
                component={Link}
                to="/login"
                name="/login"
                onClick={setRouteActive}
              >
                Login
              </Button>
            ) : (
              <Button
                color="inherit"
                component={Link}
                to="/"
                name="/sair"
                onClick={logout}
              >
                Sair
              </Button>
            )}
          </Box>
        </Toolbar>
      </StyledAppBar>
    ) : (
      <Skeleton variant='wave' height='64px' width='100%' />
    )
  )
}
