import "index.scss";
import { ThemeProvider } from 'styled-components'

import Footer from "components/Footer";
import Header from "./components/Header";
import Router from "routes";
import { defaultTheme } from 'styles/themes/default';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <div className='wrap-site'>
        <Header />
        <Router />
        <Footer />
      </div>
    </ThemeProvider>
  );
}

export default App;
