import API from "config/configs";

const checkIsLogged = () => {
  let dados = "";
  const isLogged = localStorage.getItem("isLogged");

  if (isLogged) {
    dados = JSON.parse(isLogged);
  }

  let body = {
    usuario: dados.login,
    senha: dados.senha,
  };

  return new Promise((resolve, reject) => {
    fetch(`${API}/agendamentos/login`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((resp) => resp.json())
      .then((data) => {
        resolve(data);
      });
  });
};

export default checkIsLogged;
