import styled from 'styled-components';

export const ListServices = styled.ul`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 12px;
  margin-bottom: 22px;

  li {
    margin: 4px 12px;
    border: 1px solid #ddd;
    color: #111;
    padding: 6px 10px;
    border-radius: 3px;
    cursor: pointer;

    &:hover,
    &.selecionado {
      color: #fff;
      background-color: ${props => props.theme.primaryTheme1};
    }
  }
`