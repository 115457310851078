import { FormLabel } from '@mui/material';
import InputMask from "react-input-mask";
import s from "./styles.module.scss"

export default function FieldsDataUser({nome, handleNome, telefone, handleTelefone, celular, handleCelular}) {
  return (
    <>
    <input
      type="text"
      id="nome"
      className={s.agendar__field}
      value={nome}
      onChange={handleNome}
    />

    <FormLabel htmlFor="telefone">Telefone:</FormLabel>
    <InputMask
      type="text"
      id="telefone"
      mask="(99) 9999-9999"
      className={s.agendar__field}
      value={telefone}
      onChange={handleTelefone}
    />

    <FormLabel htmlFor="celular">Celular:</FormLabel>
    <InputMask
      type="text"
      id="celular"
      mask="(99) 99999-9999"
      className={s.agendar__field}
      value={celular}
      onChange={handleCelular}
    />
    </>
  )
}