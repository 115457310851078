import API from 'config/configs';

export async function ServicosService() {
  const response = await fetch(`${API}/servicos`, {
    method: "GET",
  });

  const json = await response.json();
  return json;
}
