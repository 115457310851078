import { ServiceItem } from './styles';
import s from './styles.module.scss'

export default function SelectServices({ showServicos, funcionarioLista, listaGeralServicos, handleItemServico, funcionarioSelecionado }) {
  return (
    <>
    {showServicos && (
          <>
            <label>Selecione os serviços desejados:</label>

            <ul className={s.agendar__servicos}>
              {(() => {
                let algumItemRenderizado = false;
                const itemsRenderizados = funcionarioLista.map(
                  (funcionario) => {
                    if (funcionario.nome === funcionarioSelecionado[0].nome) {
                      return funcionario.servicos.map((item) => {
                        return listaGeralServicos.map((servico, index) => {
                          if (
                            servico.disabled !== "disabled" &&
                            servico.servico === item.servico
                          ) {
                            algumItemRenderizado = true;
                            return (
                              <ServiceItem
                                key={index}
                                onClick={handleItemServico}
                                valor={servico.preco}
                                className={servico.selected}
                              >
                                {servico.servico}
                              </ServiceItem>
                            );
                          }
                          return null;
                        });
                      });
                    }
                    return null;
                  }
                );
                
                if (!algumItemRenderizado) {
                  return [<li key="0">Nenhum serviço disponível</li>];
                }
                return itemsRenderizados;
              })()}
            </ul>
          </>
        )}
    </>
  )
}