import { useEffect, useState } from "react";
import s from "./style.module.scss";
import Modal from "components/Modal";
import ModalCadastro from "components/ModalCadastro";
import checkIsLogged from "components/IsLogged";
import { Typography, FormLabel, Card } from "@mui/material";
import SelectData from 'components/SelectData';
import SelectStaff from './components/SelectStaff';
import SelectServices from './components/SelectServices';
import SelectHours from './components/SelectHours';
import FieldsDataUser from './components/FieldsDataUser';
import { ServicosService } from 'services/servicos';
import { AgendamentosService, CreateAgendamentoService } from 'services/agendamentos';
import { FuncionariosService } from 'services/funcionarios';
import { ConfiguracoesService } from 'services/configuracoes';
import { StyledButton } from 'styles/themes/default';

const Agendar = () => {
  const [isLogado, setIsLogado] = useState(false);
  let [data, setData] = useState("");
  let [dadosAgendamento, setDadosAgendamento] = useState({});
  const [modalCadastro, setModalCadastro] = useState(false);
  const [horario, setHorario] = useState("");
  const [showAgendamento, setShowAgendamento] = useState(true);
  const [minutosItem, setMinutosItem] = useState("");
  let [horarioLista, setHorarioLista] = useState([]);
  let [funcionarioLista, setFuncionarioLista] = useState([]);
  let [listaGeralServicos, setListaGeralServicos] = useState([]);
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [total, setTotal] = useState("R$ 0,00");
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [listaServico, setListaServico] = useState([]);
  const [menorDuracao, setMenorDuracao] = useState("");
  const [mensagemModal, setMensagemModal] = useState({});
  const [showSelectHorarios, setShowSelectHorarios] = useState(false);
  const [horarioInicialAtendimento, setHorarioInicialAtendimento] = useState("");
  const [horarioFinalAtendimento, setHorarioFinalAtendimento] = useState("");
  const [horarioInicioAlmoco, setHorarioInicioAlmoco] = useState("");
  const [horarioFinalAlmoco, setHorarioFinalAlmoco] = useState("");
  const [limitDias, setLimitDias] = useState("");
  const [logoEmpresa, setLogoEmpresa] = useState("");
  const [tituloHeader, setTituloHeader] = useState("");
  const [diaSemanaHoje, setDiaSemanaHoje] = useState("");
  const [funcionarioSelecionado, setFuncionarioSelecionado] = useState([]);
  const [showServicos, setShowServicos] = useState(false);

  // start handles click e onchange
  const handleData = (e) => {
    // start configs período agendamento
    let limitadorDeDias = false;
    let limitadorDias = limitDias;

    resetDados();

    if (Number(limitDias) > 0) {
      limitadorDeDias = true;
    }
    // end configs período agendamento

    let data = e.target.value;
    data = data.replace(/\//g, "-"); // substitui eventuais barras (ex. IE) "/" por hífen "-"

    let data_array = data.split("-");
    let dia = data_array[2];
    let mes = data_array[1];
    let ano = data_array[0];

    // para o IE onde será inserido no formato dd/MM/yyyy
    if (data_array[0].length !== 4) {
      dia = data_array[0];
      mes = data_array[1];
      ano = data_array[2];
    }

    let hoje = new Date();
    let d1 = hoje.getDate();
    let m1 = hoje.getMonth() + 1;
    let a1 = hoje.getFullYear();

    let day1 = new Date(a1, m1, d1);
    let day2 = new Date(ano, mes, dia);

    let diff = day2.getTime() - day1.getTime();
    diff = diff / (1000 * 60 * 60 * 24);

    if (diff < 0) {
      console.log("Data não pode ser anterior ao dia de hoje!");
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Data não pode ser anterior ao dia de hoje!",
        status: "erro",
      });
    } else if (diff > limitadorDias && limitadorDeDias) {
      changeModal("modal-fechado", "open-modal");

      console.log(
        `Data não pode ser maior do que ${limitadorDias} dias para frente!`
      );

      setMensagemModal({
        text: `Data não pode ser maior do que ${limitadorDias} dias para frente!`,
        status: "erro",
      });
    } else {
      setData(e.target.value);

      let data = e.target.value;
      let ano = data.split("-")[0];
      let mes = Number(data.split("-")[1]);
      let dia = data.split("-")[2];

      data = `${ano}-${mes}-${dia}`;

      getFuncionarios(data);
    }
  };

  const resetDados = () => {
    let servicoSelecionadoElem = document.querySelectorAll("li.selected");

    servicoSelecionadoElem.forEach((elem) => {
      elem.classList.remove("selected");
    });

    document.getElementById("funcionario").value = "";

    setShowServicos(false);
    setShowSelectHorarios(false);
    setFuncionarioSelecionado([]);
    setListaServico([]);
    setListaGeralServicos([]);

    funcionarioSelecionado.map((item) => (item.nome = ""));

    listaServico?.forEach((item) => {
      item.selected = item.selected = "";
    });
  };

  const handleItemServico = (e) => {
    let nomeServico = e.target.textContent;
    let tempServicosSelecionados = [...servicosSelecionados];

    // ativando e inativando serviço selecionado
    listaGeralServicos?.forEach((item) => {
      if (item.servico === nomeServico && item.selected === "selected") {
        item.selected = "";

        tempServicosSelecionados?.forEach((itemServico, index) => {
          if (itemServico === nomeServico) {
            tempServicosSelecionados.splice(index, 1);
          }
        });

        setServicosSelecionados(tempServicosSelecionados);
      } else if (item.servico === nomeServico) {
        item.selected = "selected";
        setServicosSelecionados([...servicosSelecionados, nomeServico]);
      }
    });

    setListaServico(listaGeralServicos);
    getAgendamentos(data);
  };

  const validaForm = () => {
    let listaServicoSelecteds = [];

    listaServico.forEach((item) => {
      if (item.selected === "selected") {
        listaServicoSelecteds.push(item.servico);
      }
    });

    if (data === "") {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Por favor insira um data para o agendamento!",
        status: "erro",
      });
    } else if (!listaServicoSelecteds.length) {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Por favor selecione um serviço desejado para o agendamento!",
        status: "erro",
      });
    } else if (horario === "") {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Por favor selecione um horário",
        status: "erro",
      });
    } else if (nome === "") {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({ text: "Por favor preencha seu nome", status: "erro" });
    } else if (telefone === "" && celular === "") {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Por favor preencha um telefone para contato",
        status: "erro",
      });
    } else if (telefone.indexOf("_") !== -1) {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Telefone não foi preenchido corretamente",
        status: "erro",
      });
    } else if (celular.indexOf("_") !== -1) {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Celular não foi preenchido corretamente",
        status: "erro",
      });
    } else {
      return true;
    }
  };

  const postCadastro = async (body) => {
    const res = await CreateAgendamentoService()

    if (res.statusCode >= 400) {
      changeModal("modal-fechado", "open-modal");
      setMensagemModal({
        text: "Erro de sistema, por favor entre em contato!",
        status: "erro",
      });
    }else{
      changeModal("modal-fechado", "open-modal");
      setMensagemModal({
        text: "Agendamento realizado com sucesso!",
        status: "sucesso",
      });

      setDataDefault();
      setShowSelectHorarios(false);
      setNome("");
      setTelefone("");
      setCelular("");
      setTotal("R$ 0,00");

      listaServico?.forEach((item) => {
        if (item.selected === "selected") {
          item.selected = "";
        }
      });
    }
  };

  const cadastrarAgendamento = () => {
    let listaServicoSelecteds = [];
    let funcionarioSelecionadoValue = "";
    let body = "";

    listaServico?.forEach((item) => {
      if (item.selected === "selected") {
        listaServicoSelecteds.push({
          tipo: item.servico,
          duracao: item.duracaoMinutos,
          minutosItem: minutosItem,
        });
      }
    });

    let servicosSend = JSON.stringify(listaServicoSelecteds);

    if (funcionarioSelecionado.length === 0) {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Selecione um funcionário para agendar!",
        status: "erro",
      });
    } else {
      funcionarioSelecionadoValue = funcionarioSelecionado[0].nome;

      body = {
        agendamentoData: `${data}`,
        horario: `${horario}`,
        servicos: `${servicosSend}`,
        nome: `${nome}`,
        telefone: `${telefone}`,
        celular: `${celular}`,
        total: `${total}`,
        pago: false,
        funcionario: funcionarioSelecionadoValue,
      };

      setDadosAgendamento(body);

      if (validaForm() && isLogado) {
        let isLogged = JSON.parse(localStorage.getItem("isLogged"));
        body.usuario = isLogged.login;
        postCadastro(body);
      } else {
        if (validaForm()) {
          setModalCadastro(true);
          setDataDefault();
          setShowSelectHorarios(false);
          setNome("");
          setTelefone("");
          setCelular("");
          setTotal("R$ 0,00");

          listaServico?.forEach((item) => {
            if (item.selected === "selected") {
              item.selected = "";
            }
          });
        }
      }
    }
  };

  const getAgendamentos = async (dataChange) => {
    if (dataChange) {
      data = dataChange;
    }

    let body = {
      agendamentoData: `${data}`,
    };

    const agendamentos = await AgendamentosService(body)

    let listaInicialHorarios = listaHorarios();
    setShowSelectHorarios(false);

    if (agendamentos.statusCode >= 400) {
      changeModal("modal-fechado", "open-modal");
      setMensagemModal({
        text: "Erro de sistema, por favor entre em contato!",
        status: "erro",
      });
    }

    // se tiver algum horario indisponivel no dia selecionado
    if (agendamentos.length) {
      let qtdIndicesIndisponiveis = "";
      let indexHorarioAgendado = "";

      agendamentos?.forEach((agendamento) => {
        let agendamentoHorario = agendamento.horario;

        if (agendamento?.funcionario === funcionarioSelecionado[0].nome) {
          // start retirada de agendamentos indisponiveis da lista
          listaInicialHorarios?.forEach((horario, index) => {
            if (horario.hora === agendamentoHorario) {
              let agendamentoServicos = JSON.parse(agendamento.servicos);
              let agendamentoServicosSoma = agendamentoServicos.reduce(
                (a, b) => a + parseFloat(b.duracao),
                0
              );
              qtdIndicesIndisponiveis =
                agendamentoServicosSoma / menorDuracao;
              indexHorarioAgendado = index;
            } else if (
              agendamentoHorario.indexOf("ausente") >= 0 &&
              agendamentoHorario.indexOf(horario.hora) >= 0
            ) {
              horario.hora = `${horario.hora} - ausente`;
              horario.status = "ausente";
            }

            if (
              horario.hora === agendamentoHorario &&
              index === indexHorarioAgendado
            ) {
              for (let i = 0; i < qtdIndicesIndisponiveis; i++) {
                listaInicialHorarios[
                  indexHorarioAgendado
                ].hora = `${listaInicialHorarios[indexHorarioAgendado].hora} - em atendimento`;
                listaInicialHorarios[indexHorarioAgendado].status =
                  "em atendimento";
                indexHorarioAgendado++;
              }
            }
          });
          // end retirada de agendamentos indisponiveis da lista
        }
      });

      setHorarioLista(listaInicialHorarios);
    } else {
      setHorarioLista(listaInicialHorarios);
    }
  };

  const listaHorarios = () => {
    // cria lista horarios default
    // menorDuracao = 10 minutos cada serviço

    // start configs
    let horaInicial = Number(horarioInicialAtendimento.split(":")[0]);
    let horaInicialMinutos = Number(horarioInicialAtendimento.split(":")[1]);
    let horaFinal = Number(horarioFinalAtendimento.split(":")[0]); // fecha as 19hs
    let horaFinalMinutos = Number(horarioFinalAtendimento.split(":")[1]);

    // almoço
    let horaInicialAlmoco = Number(horarioInicioAlmoco.split(":")[0]);
    let minutosInicialAlmoco = Number(horarioInicioAlmoco.split(":")[1]);
    let horaFinalAlmoco = Number(horarioFinalAlmoco.split(":")[0]);
    let minutosFinalAlmoco =
      Number(horarioFinalAlmoco.split(":")[1]) - menorDuracao;
    // end configs

    let lista = [];
    let minutos = 0;
    let minutosTotal = 60;
    let contadorMinutos = 0;

    // gera hora inicial ate hora final
    for (let iHora = horaInicial; iHora <= horaFinal; iHora++) {
      // inicia contagem de minutos a partir do minuto inicial ex: 09:30
      if (iHora === horaInicial) {
        minutos = horaInicialMinutos;
      } else if (iHora === horaFinal) {
        minutosTotal = horaFinalMinutos;
      } else {
        minutos = 0;
      }

      // gera minutos pulando pela quantidade de minutos do serviço de menor duração
      for (
        let iMinutos = minutos;
        iMinutos < minutosTotal;
        iMinutos += menorDuracao
      ) {
        // setando info de horario de almoço 12:00 - 12:50
        if (
          iHora === horaInicialAlmoco &&
          iMinutos >= minutosInicialAlmoco &&
          iMinutos <= minutosTotal
        ) {
          if (iMinutos === 0) {
            lista.push({
              hora: `${iHora}:0${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          } else {
            lista.push({
              hora: `${iHora}:${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          }
          // setando info de horario de almoço 13:00 - 13:30
        } else if (
          iHora === horaFinalAlmoco &&
          iMinutos <= minutosFinalAlmoco
        ) {
          if (iMinutos === 0) {
            lista.push({
              hora: `${iHora}:0${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          } else {
            lista.push({
              hora: `${iHora}:${iMinutos}`,
              qtdMinutosItem: contadorMinutos,
              status: "em horário de almoço",
            });
          }
        } else if (iMinutos === 0) {
          lista.push({
            hora: `${iHora}:0${iMinutos}`,
            qtdMinutosItem: contadorMinutos,
          });
        } else {
          lista.push({
            hora: `${iHora}:${iMinutos}`,
            qtdMinutosItem: contadorMinutos,
          });
        }

        contadorMinutos = contadorMinutos + 10;
      }
    }

    setHorarioLista(lista);

    return lista;
  };

  const handleNome = (e) => setNome(e.target.value);

  const handleHorario = (e) => {
    let dados = JSON.parse(e.target.value);

    setHorario(dados[0].hora);
    setMinutosItem(dados[0].qtdMinutosItem);
  };

  const handleFuncionario = async (e) => {
    let dados = "";

    setListaServico([]);
    setShowSelectHorarios(false);
    setServicosSelecionados([]);
    setListaGeralServicos([]);

    if (e.target.value !== "") {
      dados = JSON.parse(e.target.value);
      setFuncionarioSelecionado(dados);

      funcionarioLista?.forEach(async (funcionario) => {
        if (dados[0].nome === funcionario.nome) {
          
          const servicos = await ServicosService()
          
          let listaTemp = [];

          servicos?.forEach((item) => {
            listaTemp.push(item);

            funcionario?.servicos?.forEach((itemFuncionario) => {
              if (item.servico === itemFuncionario.servico) {
                itemFuncionario.duracaoMinutos = item.duracaoMinutos;
                item.preco = `R$ ${item.preco.split("R$")[1].trim()}`;
                itemFuncionario.preco = item.preco;
              }
            });
          });

          funcionario?.servicos?.forEach((servico) => {
            servico.selected = "";
          });

          setListaGeralServicos(listaTemp);
          setListaServico(funcionario.servicos);
          setShowServicos(true);

          if (funcionario[diaSemanaHoje]) {
            let getListaHorarios = JSON.parse(funcionario[diaSemanaHoje]);

            getListaHorarios?.forEach((hora) => {
              setHorarioInicialAtendimento(hora.horarioInicio);
              setHorarioFinalAtendimento(hora.horarioFinal);
              setHorarioInicioAlmoco(hora.horarioInicioAlmocoFuncionario);
              setHorarioFinalAlmoco(hora.horarioFinalAlmocoFuncionario);
            });
          }
        }
      });
    }
  };

  const handleTelefone = (e) => setTelefone(e.target.value);
  const handleCelular = (e) => setCelular(e.target.value);
  // end handles click e onchange

  const changeModal = (seletorModal, changeIdModal) => {
    let modal = document.getElementById(seletorModal);
    if (modal !== null) {
      modal.id = changeIdModal;
    }
  };

  // start ready
  const setDataDefault = () => {
    const date = new Date();
    const format = "aaaa-mm-dd";
    const diaSemana = date.getDay();

    const DiasDaSemana = {
      0: "domingo",
      1: "segunda",
      2: "terca",
      3: "quarta",
      4: "quinta",
      5: "sexta",
      6: "sabado",
    };

    setDiaSemanaHoje(DiasDaSemana[diaSemana]);

    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      aa: date.getFullYear().toString().slice(-2),
      aaaa: date.getFullYear(),
    };

    if (map.dd === 1) map.dd = "01";
    if (map.dd === 2) map.dd = "02";
    if (map.dd === 3) map.dd = "03";
    if (map.dd === 4) map.dd = "04";
    if (map.dd === 5) map.dd = "05";
    if (map.dd === 6) map.dd = "06";
    if (map.dd === 7) map.dd = "07";
    if (map.dd === 8) map.dd = "08";
    if (map.dd === 9) map.dd = "09";

    if (map.mm === 1) map.mm = "01";
    if (map.mm === 2) map.mm = "02";
    if (map.mm === 3) map.mm = "03";
    if (map.mm === 4) map.mm = "04";
    if (map.mm === 5) map.mm = "05";
    if (map.mm === 6) map.mm = "06";
    if (map.mm === 7) map.mm = "07";
    if (map.mm === 8) map.mm = "08";
    if (map.mm === 9) map.mm = "09";

    let dateFormatted = format.replace(
      /mm|dd|aaaa/gi,
      (matched) => map[matched]
    );
    setData(dateFormatted);
  };

  const getFuncionarios = async (newData) => {
    let newDataValue = newData; // 2024-10-28

    const funcionarios = await FuncionariosService()

    let listaTemp = [];
    let dataHoje = "";

    if (newDataValue) {
        const [year, month, day] = newDataValue.split('-');
      dataHoje = new Date(year, month - 1, day);
      console.log('if')
    } else {
      console.log('else')
      dataHoje = new Date();
    }

    dataHoje.setHours(0, 0, 0, 0);

    const diaSemana = dataHoje.getUTCDay();

    const DiasDaSemana = {
      0: "domingo",
      1: "segunda",
      2: "terca",
      3: "quarta",
      4: "quinta",
      5: "sexta",
      6: "sabado",
    };

    let semanaDia = DiasDaSemana[diaSemana];
    setDiaSemanaHoje(semanaDia);

    // eslint-disable-next-line array-callback-return
    funcionarios.map((funcionario) => {
      let nome = funcionario?.nomeFuncionario;
      let id = funcionario?.id;
      let servicos = JSON.parse(funcionario?.servicos);
      let segunda = funcionario?.segunda;
      let terca = funcionario?.terca;
      let quarta = funcionario?.quarta;
      let quinta = funcionario?.quinta;
      let sexta = funcionario?.sexta;
      let sabado = funcionario?.sabado;
      let domingo = funcionario?.domingo;

      // se o funcionario atende no dia da semana de hoje exibe ele na lista
      if (funcionario[semanaDia]) {
        listaTemp.push({
          id,
          nome,
          servicos,
          segunda,
          terca,
          quarta,
          quinta,
          sexta,
          sabado,
          domingo,
        });
      }
    });

    if (listaTemp.length === 0) {
      changeModal("modal-fechado", "open-modal");

      setMensagemModal({
        text: "Não existem funcionários disponíveis para o dia selecionado!",
        status: "erro",
      });
      setShowAgendamento(false);
      setFuncionarioLista([]);
    } else {
      changeModal("open-modal", "modal-fechado");

      setShowAgendamento(true);
      setFuncionarioLista(listaTemp);
    }
  };

  const getServicos = async () => {
    const config = await ConfiguracoesService()

    if (config.length) {
      setLimitDias(config[0].limitadorDias);

      if (config[0].fotoLogo !== null) {
        if (config[0].fotoLogo.indexOf("assets") !== -1) {
          let fotoName = config[0].fotoLogo.split("assets/")[1];
          setLogoEmpresa(`assets/${fotoName}`);
        }
      }
      if (config[0].fotoLogo === null || config[0].fotoLogo === "") {
        // valor default quando tiver registro com id 1 e outros campos vazios
        setLogoEmpresa("assets/logoDefault.png");
      }

      setTituloHeader(config[0].tituloHeader);
    } else {
      // valor default
      setLogoEmpresa("assets/logoDefault.png");
    }
    
    const servicos = await ServicosService()
    if (servicos.length) {
      // obtendo servico de menor duracao
      let duracaoMinutos = servicos.map((item) => {
        return item.duracaoMinutos;
      });

      let menorDuracao = Math.min.apply(Math, duracaoMinutos);
      setMenorDuracao(menorDuracao);
    }

    getFuncionarios();
  };
  // end ready

  // start - executa updates
  const somaTotal = () => {
    let formato = {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    };
    let valorServico = "";
    let listaSelecionado = [];

    let newLista = listaServico?.map((item) => {
      valorServico = item.preco;

      if (String(valorServico).indexOf("R$") >= 0) {
        valorServico = valorServico.replace("R$ ", "");
        valorServico = parseFloat(valorServico.replace(",", "."));
        item.preco = valorServico;
      }

      return item;
    });

    newLista.map((item, index) => {
      if (item.selected === "selected") {
        listaSelecionado.push(item);
      }
    });

    let totalSoma = listaSelecionado.reduce((a, b) => a + b.preco, 0);
    setTotal(totalSoma.toLocaleString("pt-BR", formato));
  };

  const checkHorariosAgendamento = () => {
    let listaServicoTempo = [];
    let tam = horarioLista.length;

    // soma valor total de servicos a ser agendados
    servicosSelecionados?.forEach((servico) => {
      listaServico?.forEach((item) => {
        if (servico === item.servico) {
          listaServicoTempo.push({
            nome: servico,
            duracao: parseFloat(item.duracaoMinutos),
          });
        }
      });
    });

    let listaServicoTempoSoma = listaServicoTempo.reduce(
      (a, b) => a + b.duracao,
      0
    );
    let indicesParaAgendar = listaServicoTempoSoma / menorDuracao - 1;

    // horarios lista por funcionario
    // verificar quais horarios podem ser agendados com base na soma de minutos em ordem da lista para agendar
    horarioLista?.forEach((item) => {
      // rodar o numero de indices que precisa ser feito o agendamento ex 30 min 3 vezes para vericar as 3 posicao estao disponiveis
      // verificando se o item atual ate o item onde precisa agendar tem disponibilidade
      horarioLista?.forEach((itemCheck, indexCheck) => {
        if (itemCheck.hora === item.hora) {
          if (indexCheck + indicesParaAgendar < tam) {
            // roda por mais 3 indices
            for (
              let i = indexCheck;
              i <= indexCheck + indicesParaAgendar;
              i++
            ) {
              if (
                horarioLista[i].status === "ausente" ||
                horarioLista[i].status === "em horário de almoço" ||
                horarioLista[i].status === "em atendimento"
              ) {
                horarioLista[indexCheck].status = "indisponível";
                horarioLista[
                  indexCheck
                ].hora = `${horarioLista[indexCheck].hora} - indisponível`;
              }
            }
          } else {
            horarioLista[indexCheck].status = "indisponível";
            horarioLista[
              indexCheck
            ].hora = `${horarioLista[indexCheck].hora} - indisponível`;
          }
        }
      });
    });

    if (funcionarioSelecionado.length > 0) {
      if (
        horarioLista.length &&
        servicosSelecionados.length &&
        funcionarioSelecionado[0].nome !== ""
      ) {
        setShowSelectHorarios(true);
      }
    }
  };
  // end - executa updates

  // ready
  useEffect(() => {
    setDataDefault();
    getServicos();
    checkIsLogged().then((res) => {
      let isLogged = localStorage.getItem("isLogged");

      if (isLogged !== "" && isLogged !== null) {
        isLogged = JSON.parse(isLogged);

        res?.forEach((login) => {
          if (
            login.usuario === isLogged.login &&
            login.senha === isLogged.senha
          ) {
            setIsLogado(true);
          }
        });
      }
    });
  }, []);

  // quando servicos selecionados for atualizado
  useEffect(() => {
    somaTotal();
    checkHorariosAgendamento();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listaServico, servicosSelecionados, horarioLista]);

  return (
    <main className={s.contentAgendar}>
      <Modal mensagem={mensagemModal} />
      <ModalCadastro open={modalCadastro} dados={dadosAgendamento} />

      <div className={s.agendarFoto}>
        <img src={logoEmpresa} alt={tituloHeader} />
      </div>
      <Card className={s.agendar}>
        <Typography variant="h2">Faça aqui seu agendamento:</Typography>
        <SelectData handleData={handleData} data={data} />
        <SelectStaff handleFuncionario={handleFuncionario} funcionarioLista={funcionarioLista} />
        <SelectServices
          showServicos={showServicos}
          funcionarioLista={funcionarioLista}
          listaGeralServicos={listaGeralServicos}
          handleItemServico={handleItemServico}
          funcionarioSelecionado={funcionarioSelecionado}
        />
        <SelectHours showSelectHorarios={showSelectHorarios} handleHorario={handleHorario} horarioLista={horarioLista} servicosSelecionados={servicosSelecionados} />
        <FormLabel htmlFor="nome">Nome:</FormLabel>
        <FieldsDataUser nome={nome} handleNome={handleNome} celular={celular} handleCelular={handleCelular} telefone={telefone} handleTelefone={handleTelefone} />

        <div className={s.wrapTotal}>
          <FormLabel htmlFor="total">Total:</FormLabel>
          <Typography variant="body1" className={s.agendar__total}>
            <strong>{total}</strong>
          </Typography>
        </div>

        {showAgendamento && (
          <StyledButton onClick={cadastrarAgendamento}>Agendar</StyledButton>
        )}
      </Card>
    </main>
  );
};

export default Agendar;
