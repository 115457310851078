import { useState } from "react";
import InputMask from "react-input-mask";

import style from "components/ModalCadastro/style.module.scss";
import API from "config/configs";
import Modal from "components/Modal";

const ModalCadastro = ({ open, dados }) => {
  const [senha, setSenha] = useState("");
  const [usuario, setUsuario] = useState("");
  const [openModal, setOpenModal] = useState(true);
  const [verSenha, setVerSenha] = useState("password");
  const [mensagemModal, setMensagemModal] = useState({});
  const [verSenhaTitle, setVerSenhaTitle] = useState("Mostrar senha");

  const handleSenha = (e) => setSenha(e.target.value);
  const handleUsuario = (e) => setUsuario(e.target.value);

  const validaEmail = () => {
    var emailValid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailValid.test(String(usuario).toLowerCase());
  };

  const validaSenha = () => {
    if (senha === "") {
      return false;
    }

    return true;
  };

  const postCadastro = (body, dadosUsuario) => {
    let dadosSessaoLogado = JSON.stringify({
      login: dadosUsuario.usuario,
      senha: dadosUsuario.senha,
    });
    body.usuario = dadosUsuario.usuario;

    // Cadastro de agendamento associado ao usuario logado
    fetch(`${API}/agendamentos/agendamento`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);

        setMensagemModal({
          text: "Cadastro e Agendamento realizado com sucesso!",
          status: "sucesso",
        });

        localStorage.setItem("isLogged", dadosSessaoLogado);

        setSenha("");
        setUsuario("");
        setOpenModal(false);
      })
      .catch((err) => {
        console.log(err);

        setMensagemModal({
          text: "Erro de sistema, por favor entre em contato!",
          status: "erro",
        });
      });
  };

  const postCadastroLogado = (body, dadosUsuario) => {
    let dadosSessaoLogado = JSON.stringify({
      login: dadosUsuario.usuario,
      senha: dadosUsuario.senha,
    });
    body.usuario = dadosUsuario.usuario;

    let bodyLogin = {
      usuario: dadosUsuario.usuario,
      senha: dadosUsuario.senha,
    };

    // login e agendamento
    fetch(`${API}/agendamentos/login`, {
      method: "POST",
      body: JSON.stringify(bodyLogin),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.length) {
          // agendamento:
          fetch(`${API}/agendamentos/agendamento`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: { "Content-type": "application/json; charset=UTF-8" },
          })
            .then((response) => response.json())
            .then((json) => {
              setMensagemModal({
                text: "Agendamento e login realizado com sucesso!",
                status: "sucesso",
              });

              localStorage.setItem("isLogged", dadosSessaoLogado);

              setSenha("");
              setUsuario("");
              setOpenModal(false);
            })
            .catch((err) => {
              console.log(err);

              setMensagemModal({
                text: "Erro de sistema, por favor entre em contato!",
                status: "erro",
              });
            });
        } else {
          setMensagemModal({
            text: "Usuário já possui cadastro, senha informada esta incorreta!",
            status: "erro",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cadastrarUsuario = () => {
    let body = {
      usuario: usuario,
    };

    if (!validaEmail()) {
      setMensagemModal({
        text: "E-mail informado não é válido!",
        status: "erro",
      });
    } else if (!validaSenha()) {
      setMensagemModal({
        text: "Informe uma senha para o cadastro!",
        status: "erro",
      });
    }

    fetch(`${API}/agendamentos/login`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        let body = {
          senha: senha,
          usuario: usuario,
        };

        setUsuario(usuario);
        setSenha(senha);

        console.log("usuario", usuario);
        console.log("senha", senha);

        if (json.length) {
          // usuario ja possui cadastro realizar login do usuário e agendamento
          if (validaEmail() && validaSenha()) {
            postCadastroLogado(dados, body);
          }
        } else {
          // se não existe usuário cadastro no banco realiza cadastro e agendamento:
          // valida cpf e senha
          if (validaEmail() && validaSenha()) {
            fetch(`${API}/agendamentos/cadastrar`, {
              method: "POST",
              body: JSON.stringify(body),
              headers: { "Content-type": "application/json; charset=UTF-8" },
            })
              .then((response) => response.json())
              .then((json) => {
                // console.log('cadastro via modal de agendamento:', json)
                postCadastro(dados, body);
              })
              .catch((err) => {
                console.log(err);

                setMensagemModal({
                  text: "Erro de sistema, por favor entre em contato!",
                  status: "erro",
                });
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mostrarSenha = () => {
    setVerSenha("text");

    if (verSenha === "text") {
      setVerSenha("password");
    }
  };

  return (
    <>
      {open && <Modal mensagem={mensagemModal} />}

      {openModal && open && (
        <div className={style.login}>
          <div className={style.login__container}>
            <h2 className={style.login__title}>
              Cadastre-se, criando um usuário e senha:
            </h2>

            <label htmlFor="usuario" title="E-mail">
              E-mail:
            </label>
            <input
              type="email"
              id="usuario"
              placeholder="Digite seu e-mail..."
              className={style.login__field}
              value={usuario}
              onChange={handleUsuario}
            />

            <label htmlFor="senha">Senha:</label>
            <div className={style.login__fieldSenha}>
              <div
                className={verSenha + " fieldLogin"}
                title={verSenhaTitle}
                onClick={mostrarSenha}
              ></div>
              <input
                type={verSenha}
                id="senha"
                placeholder="Digite sua senha..."
                className={style.login__field}
                value={senha}
                onChange={handleSenha}
              />
            </div>

            <button className="button" onClick={cadastrarUsuario}>
              Cadastrar e Agendar
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalCadastro;
