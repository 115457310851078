import { useEffect, useState } from "react";
import Modal from "components/Modal";
import InputMask from "react-input-mask";
import { Link } from "react-router-dom";

import style from "./style.module.scss";
import checkIsLogged from "components/IsLogged";
import API from "config/configs";

const Agendamentos = () => {
  let [data, setData] = useState("");
  const [mensagemModal, setMensagemModal] = useState({});
  const [lista, setLista] = useState([]);
  const [isLogado, setIsLogado] = useState(false);
  const [isAdministrador, setIsAdministrador] = useState(false);
  const [updatePago, setUpdatePago] = useState({});
  const [deleteRegistro, setDeleteRegistro] = useState("");
  const [usuario, setUsuario] = useState("");
  const [valueFiltro, setValueFiltro] = useState(true);
  const CPFAdmin = "lksribeiro2014@gmail.com";

  const listaAgendamentos = (usuario, data) => {
    let listaTemp = [];

    let body = {
      usuario: usuario,
    };

    let dt = data.split("-");

    let dia = dt[2];
    let mes = dt[1];
    let ano = dt[0];

    const format = "aaaa-mm-dd";

    const map = {
      mm: mes,
      dd: dia,
      aa: ano,
      aaaa: ano,
    };

    if (map.dd === "1") map.dd = "01";
    if (map.dd === "2") map.dd = "02";
    if (map.dd === "3") map.dd = "03";
    if (map.dd === "4") map.dd = "04";
    if (map.dd === "5") map.dd = "05";
    if (map.dd === "6") map.dd = "06";
    if (map.dd === "7") map.dd = "07";
    if (map.dd === "8") map.dd = "08";
    if (map.dd === "9") map.dd = "09";

    if (map.mm === "1") map.mm = "01";
    if (map.mm === "2") map.mm = "02";
    if (map.mm === "3") map.mm = "03";
    if (map.mm === "4") map.mm = "04";
    if (map.mm === "5") map.mm = "05";
    if (map.mm === "6") map.mm = "06";
    if (map.mm === "7") map.mm = "07";
    if (map.mm === "8") map.mm = "08";
    if (map.mm === "9") map.mm = "09";

    let dateFormatted = format.replace(
      /mm|dd|aaaa/gi,
      (matched) => map[matched]
    );

    if (usuario === CPFAdmin) {
      setIsAdministrador(true);

      let body = {
        agendamentoData: `${dateFormatted}`,
      };

      fetch(`${API}/agendamentos/horarios`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
        .then((response) => response.json())
        .then((data) => {
          setLista(data);
        })
        .catch((err) => {
          console.log("Erro na exibição dos agendamentos", err);
        });
    } else {
      fetch(`${API}/agendamentos/usuario`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      })
        .then((response) => response.json())
        .then((agendamentos) => {
          agendamentos.map((item, index) => {
            listaTemp.push(item);
          });

          setLista(agendamentos);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const setDataDefault = (usuario) => {
    const date = new Date();
    const format = "aaaa-mm-dd";

    const map = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      aa: date.getFullYear().toString().slice(-2),
      aaaa: date.getFullYear(),
    };

    if (map.dd === 1) map.dd = "01";
    if (map.dd === 2) map.dd = "02";
    if (map.dd === 3) map.dd = "03";
    if (map.dd === 4) map.dd = "04";
    if (map.dd === 5) map.dd = "05";
    if (map.dd === 6) map.dd = "06";
    if (map.dd === 7) map.dd = "07";
    if (map.dd === 8) map.dd = "08";
    if (map.dd === 9) map.dd = "09";

    if (map.mm === "1") map.mm = "01";
    if (map.mm === "2") map.mm = "02";
    if (map.mm === "3") map.mm = "03";
    if (map.mm === "4") map.mm = "04";
    if (map.mm === "5") map.mm = "05";
    if (map.mm === "6") map.mm = "06";
    if (map.mm === "7") map.mm = "07";
    if (map.mm === "8") map.mm = "08";
    if (map.mm === "9") map.mm = "09";

    let dateFormatted = format.replace(
      /mm|dd|aaaa/gi,
      (matched) => map[matched]
    );
    setData(dateFormatted);
    listaAgendamentos(usuario, dateFormatted);
  };

  const handleData = (e) => {
    setData(e.target.value);
  };

  const marcarPago = (e) => {
    let id = e.target.value;

    let body = {
      pago: "Sim",
      id: id,
    };

    fetch(`${API}/agendamentos/pago`, {
      method: "put",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        setUpdatePago(body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const desmarcarPago = (e) => {
    let id = e.target.value;

    let body = {
      pago: "Não",
      id: id,
    };

    fetch(`${API}/agendamentos/pago`, {
      method: "put",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        setUpdatePago(body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelarAgendamento = (e) => {
    let id = e.target.value;

    let body = {
      id: id,
    };

    fetch(`${API}/agendamentos/cancelar`, {
      method: "delete",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        setMensagemModal({
          text: "Agendamento cancelado com sucesso",
          status: "sucesso",
        });
        setDeleteRegistro(id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filtrarAgendamentoNome = (e) => {
    let valueFiltro = e.target.value;
    let newLista = [];

    lista.map((agendamento) => {
      let nome = agendamento.nome.toLowerCase();
      valueFiltro = valueFiltro.toLowerCase();

      if (nome.indexOf(valueFiltro) >= 0) {
        agendamento.exibeItem = true;
      } else {
        agendamento.exibeItem = false;
      }

      setValueFiltro(true);
      newLista.push(agendamento);
    });

    setLista(newLista);
  };

  const filtrarAgendamentoFuncionario = (e) => {
    let valueFiltro = e.target.value;
    let newLista = [];

    lista.map((agendamento) => {
      let nome = agendamento.funcionario.toLowerCase();
      valueFiltro = valueFiltro.toLowerCase();

      if (nome.indexOf(valueFiltro) >= 0) {
        agendamento.exibeItem = true;
      } else {
        agendamento.exibeItem = false;
      }

      setValueFiltro(true);
      newLista.push(agendamento);
    });

    setLista(newLista);
  };

  const filtrarAgendamentoData = (e) => {
    let valueFiltro = e.target.value;
    let newLista = [];

    lista.map((agendamento) => {
      let data = agendamento.agendamentoData;
      valueFiltro = valueFiltro;

      if (data.indexOf(valueFiltro) >= 0) {
        agendamento.exibeItem = true;
      } else {
        agendamento.exibeItem = false;
      }

      setValueFiltro(true);
      newLista.push(agendamento);
    });

    setLista(newLista);
  };

  const filtrarAgendamentoTelefone = (e) => {
    let valueFiltro = e.target.value;
    let newLista = [];

    lista.map((agendamento) => {
      let celular = agendamento.celular.replace(/[^0-9]+/g, "");
      let telefone = agendamento.telefone.replace(/[^0-9]+/g, "");
      valueFiltro = valueFiltro.replace(/[^0-9]+/g, "");

      if (
        celular.indexOf(valueFiltro) >= 0 ||
        telefone.indexOf(valueFiltro) >= 0
      ) {
        agendamento.exibeItem = true;
      } else {
        agendamento.exibeItem = false;
      }

      setValueFiltro(true);
      newLista.push(agendamento);
    });

    setLista(newLista);
  };

  useEffect(() => {
    checkIsLogged().then((res) => {
      let isLogged = localStorage.getItem("isLogged");

      if (isLogged !== "" && isLogged !== null) {
        isLogged = JSON.parse(isLogged);

        res.map((login) => {
          if (
            login.usuario === isLogged.login &&
            login.senha === isLogged.senha
          ) {
            let usuario = login.usuario;
            setIsLogado(true);
            setUsuario(usuario);
            setDataDefault(usuario);
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    listaAgendamentos(usuario, data);
  }, [updatePago, data, usuario, valueFiltro, deleteRegistro]);

  return (
    <main className={style.agendamentos}>
      <Modal mensagem={mensagemModal} />
      <h2 className={style.agendamentos__title}>Meus Agendamentos</h2>

      {isLogado && isAdministrador && (
        <>
          <h4 className={style.agendamentos__titleFiltro}>
            Filtrar por telefone do cliente:
          </h4>
          <InputMask
            type="text"
            mask="(99) 99999-9999"
            onChange={filtrarAgendamentoTelefone}
            className={style.agendamentos__filtro}
          />

          <h4 className={style.agendamentos__titleFiltro}>
            Filtrar por nome do cliente:
          </h4>
          <InputMask
            type="text"
            onChange={filtrarAgendamentoNome}
            className={style.agendamentos__filtro}
          />

          <h4 className={style.agendamentos__titleFiltro}>
            Filtrar por funcionário:
          </h4>
          <InputMask
            type="text"
            onChange={filtrarAgendamentoFuncionario}
            className={style.agendamentos__filtro}
          />

          <h4 className={style.agendamentos__titleFiltro}>
            Agendamentos do dia:
          </h4>
          <input
            type="date"
            id="data"
            className={style.agendamentos__field}
            value={data}
            onChange={handleData}
          />
        </>
      )}

      {
        //usuário comum
        isLogado && !isAdministrador && (
          <>
            <h4 className={style.agendamentos__titleFiltro}>
              Filtrar por data:
            </h4>
            <InputMask
              type="date"
              onChange={filtrarAgendamentoData}
              className={style.agendamentos__filtro}
            />
          </>
        )
      }

      {isLogado && (
        <>
          {lista.length ? (
            ""
          ) : (
            <p className="center">Não há agendamentos cadastrados.</p>
          )}

          <ul className={style.agendamentos__list}>
            {lista
              .slice() // Crie uma cópia da lista para evitar modificar a lista original
              .sort((a, b) => {
                // Extrair a hora e o minuto de cada horário e converter em formato numérico
                const horarioA = a.horario.split(":");
                const horarioB = b.horario.split(":");
                const horaA = parseInt(horarioA[0]);
                const minutoA = parseInt(horarioA[1]);
                const horaB = parseInt(horarioB[0]);
                const minutoB = parseInt(horarioB[1]);

                // Comparar as horas e os minutos numericamente
                if (horaA !== horaB) {
                  return horaA - horaB; // Comparação das horas
                } else {
                  return minutoA - minutoB; // Comparação dos minutos se as horas forem iguais
                }
              })
              .map((agendamento, index) => {
                let data = agendamento.agendamentoData;
                let dataFormatada = data.split("-");
                let dia = dataFormatada[2];
                let mes = dataFormatada[1];
                let ano = dataFormatada[0];
                let servicos = JSON.parse(agendamento.servicos);
                let listaNomesServicos = [];

                servicos.map((servico) => {
                  listaNomesServicos.push(servico.tipo);
                });

                if (isAdministrador) {
                  if (
                    agendamento.exibeItem ||
                    agendamento.exibeItem === undefined
                  ) {
                    return (
                      <li className={style.agendamentos__item} key={index}>
                        <p>
                          <strong>Data:</strong> {`${dia}/${mes}/${ano}`}
                        </p>
                        <p>
                          <strong>Horário:</strong> {agendamento.horario}
                        </p>
                        <p>
                          <strong>Nome:</strong> {agendamento.nome}
                        </p>
                        {agendamento.telefone && (
                          <p>
                            <strong>Telefone:</strong> {agendamento.telefone}
                          </p>
                        )}
                        {agendamento.celular && (
                          <p>
                            <strong>Celular:</strong> {agendamento.celular}
                          </p>
                        )}
                        {servicos.length > 0 && (
                          <p>
                            <strong>Serviços: </strong>
                            {listaNomesServicos.join(",")}
                          </p>
                        )}

                        {agendamento.total && (
                          <p>
                            <strong>Total:</strong> {agendamento.total}
                          </p>
                        )}

                        {agendamento.funcionario && (
                          <p>
                            <strong>Funcionário:</strong>{" "}
                            {agendamento.funcionario}
                          </p>
                        )}

                        {agendamento.pago && (
                          <p className={agendamento.classe}>
                            <strong>Pago:</strong> {agendamento.pago}
                          </p>
                        )}

                        <div className={style.agendamentos__buttons}>
                          <button
                            className="button"
                            value={agendamento.id}
                            onClick={cancelarAgendamento}
                          >
                            Cancelar Agendamento
                          </button>

                          {agendamento.pago === "Não" && (
                            <button
                              className="button"
                              value={agendamento.id}
                              onClick={marcarPago}
                            >
                              Marcar Pago
                            </button>
                          )}
                          {agendamento.pago === "Sim" && (
                            <button
                              className="button"
                              value={agendamento.id}
                              onClick={desmarcarPago}
                            >
                              DesMarcar Pago
                            </button>
                          )}
                        </div>
                      </li>
                    );
                  }
                } else {
                  // Se for usuário
                  if (
                    agendamento.exibeItem ||
                    agendamento.exibeItem === undefined
                  ) {
                    return (
                      <li className={style.agendamentos__item} key={index}>
                        <p>
                          <strong>Data:</strong> {`${dia}/${mes}/${ano}`}
                        </p>
                        <p>
                          <strong>Horário:</strong> {agendamento.horario}
                        </p>
                        <p>
                          <strong>Nome:</strong> {agendamento.nome}
                        </p>
                        {agendamento.telefone && (
                          <p>
                            <strong>Telefone:</strong> {agendamento.telefone}
                          </p>
                        )}
                        {agendamento.celular && (
                          <p>
                            <strong>Celular:</strong> {agendamento.celular}
                          </p>
                        )}
                        <p>
                          <strong>Serviços: </strong>
                          {listaNomesServicos.join(",")}
                        </p>
                        <p>
                          <strong>Total:</strong> {agendamento.total}
                        </p>
                        <button
                          className="button"
                          value={agendamento.id}
                          onClick={cancelarAgendamento}
                        >
                          Cancelar Agendamento
                        </button>
                      </li>
                    );
                  } else {
                    return (
                      <p className="center">Não há agendamentos cadastrados.</p>
                    );
                  }
                }
              })}
          </ul>
        </>
      )}

      {!isLogado && (
        <>
          <p className={style.agendamentos__textInfo}>
            Realize seu login para ver os seus agendamentos:
          </p>
          <Link to={`/login`} className={style.agendamentos__textInfoLink}>
            {" "}
            Fazer Login
          </Link>
        </>
      )}
    </main>
  );
};

export default Agendamentos;
