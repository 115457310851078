import { FormLabel } from '@mui/material'
import s from './styles.module.scss'

export default function SelectStaff({funcionarioLista, handleFuncionario}) {
  return (
    <>
      <FormLabel htmlFor="funcionario">Selecione um funcionário:</FormLabel>
      <select
        name="funcionario"
        id="funcionario"
        className={s.agendar__field}
        onChange={handleFuncionario}
      >
        <option value="">Selecione um funcionário</option>
        {funcionarioLista.map((item, index) => {
          return (
            <option
              value={`[{"id": "${item.id}", "nome": "${item.nome}"}]`}
              key={index}
            >
              {item.nome}
            </option>
          );
        })}
      </select>
    </>
  )
}