import { useEffect, useState } from "react";
import checkIsLogged from "components/IsLogged";
import { CurrencyInput } from "react-currency-mask";
import Modal from "components/Modal";
import API from "config/configs";
import style from "./style.module.scss";

const Servicos = () => {
  const [lista, setLista] = useState([]);
  const administrador = "lksribeiro2014@gmail.com";
  const [field, setShowField] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [mensagemModal, setMensagemModal] = useState({});
  const [idDeleted, setIdDeleted] = useState("");
  const [servicoEdit, setServicoEdit] = useState("");
  const [precoEdit, setPrecoEdit] = useState("");
  const [duracaoEdit, setDuracaoEdit] = useState("");

  const getPrecos = () => {
    let listaTemp = [];

    fetch(`${API}/servicos`)
      .then((res) => res.json())
      .then((precos) => {
        precos.map((item, index) => {
          const converter = (minutos) => {
            const horas = Math.floor(minutos / 60);
            const min = minutos % 60;
            const textoHoras = `00${horas}`.slice(-2);
            const textoMinutos = `00${min}`.slice(-2);

            return `${textoHoras}:${textoMinutos}`;
          };

          item.duracaoMinutos = converter(item.duracaoMinutos);
          listaTemp.push(item);
        });

        setLista(listaTemp);
      });
  };

  const handleServicoEdit = (e) => setServicoEdit(e.target.value);
  const handleDuracaoEdit = (e) => setDuracaoEdit(e.target.value);

  const editarServico = (e) => {
    let id = e.target.id;
    let preco = e.target.dataset.preco;
    let duracao = e.target.dataset.duracao;
    let nome = e.target.dataset.nome;

    setServicoEdit(nome);
    setPrecoEdit(preco);
    setDuracaoEdit(duracao);
    setShowEdit(true);
    setIdDeleted(id);
  };

  const salvarEdicao = (e) => {
    let id = e.target.dataset.edit;

    let body = {
      id: id,
      servico: servicoEdit,
      preco: precoEdit,
      duracaoMinutos: duracaoEdit,
    };

    fetch(`${API}/servico/edit`, {
      method: "put",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    });

    setMensagemModal({
      text: "Edição realizada com sucesso",
      status: "sucesso",
    });

    setTimeout(function () {
      getPrecos();
    }, 1000);

    setShowEdit(false);
  };

  const deletarServico = (e) => {
    let id = e.target.id;
    let name = e.target.name;

    let body = {
      id: id,
    };

    // atualiza servicos nos funcionarios e depois faz o delete
    fetch(`${API}/servicos/deletar`, {
      method: "delete",
      body: JSON.stringify(body),
      headers: { "Content-type": "application/json; charset=UTF-8" },
      async: false,
    })
      .then((response) => response.json())
      .then((json) => {
        setIdDeleted(id);
        console.log("Dados removidos com sucesso", json);
        setMensagemModal({
          text: `Serviço "${name}" removido com sucesso`,
          status: "sucesso",
        });

        setShowEdit(false);

        setTimeout(function () {
          getPrecos();
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPrecos();

    checkIsLogged().then((res) => {
      let isLogged = localStorage.getItem("isLogged");

      if (isLogged !== "" && isLogged !== null) {
        isLogged = JSON.parse(isLogged);

        res.map((login) => {
          if (
            login.usuario === isLogged.login &&
            login.senha === isLogged.senha
          ) {
            let usuario = login.usuario;

            if (usuario === administrador) {
              setShowField(true);
            }
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    getPrecos();
  }, [idDeleted]);

  return (
    <main className={style.tabelaPrecos}>
      <Modal mensagem={mensagemModal} />
      <h2 className="title">Tabela de Serviços</h2>

      <div className={style.wrapTable}>
        <table>
          <thead>
            <tr>
              <th>Serviço</th>
              <th>Preço</th>
              <th>Duração</th>
              {field && <th>Deletar</th>}
            </tr>
          </thead>
          <tbody>
            {lista.map((servico, index) => {
              return (
                <tr key={index} id={servico.id}>
                  <td>{servico.servico}</td>
                  <td>{servico.preco}</td>
                  <td>{servico.duracaoMinutos}h</td>

                  {field && (
                    <td>
                      <button
                        className="button deletar"
                        name={servico.servico}
                        id={servico.id}
                        onClick={deletarServico}
                      >
                        Deletar
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {field && showEdit && (
        <div className={style.formEdicao}>
          <div className={style.fieldSeparador}>
            <label htmlFor="servico">Serviço:</label>
            <input
              type="text"
              className=""
              id="servico"
              value={servicoEdit}
              disabled
              onChange={handleServicoEdit}
            />
          </div>
          <div className={style.fieldSeparador}>
            <label htmlFor="preco">Preço:</label>
            <CurrencyInput
              placeholder="R$ 0,00"
              id="preco"
              value={precoEdit}
              onChangeValue={(event, originalValue, maskedValue) => {
                setPrecoEdit(maskedValue);
              }}
            />
          </div>
          <div className={style.fieldSeparador}>
            <label htmlFor="duracao">Duração em minutos:</label>
            <input
              type="text"
              className=""
              id="duracao"
              value={duracaoEdit}
              onChange={handleDuracaoEdit}
            />
          </div>

          <button
            className="button salvar-servico"
            data-edit={idDeleted}
            onClick={salvarEdicao}
          >
            Salvar
          </button>
        </div>
      )}
    </main>
  );
};

export default Servicos;
