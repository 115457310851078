import { API } from 'services';
const base = `${API}/agendamentos`;

export async function loginService(data) {
  const response = await fetch(`${base}/login`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });

  const json = await response.json();
  return json;
}
