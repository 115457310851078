import style from "components/Modal/style.module.scss";
import { useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";

const Modal = ({ mensagem }) => {

  const [openModal, setOpenModal] = useState("")
  const navigate = useNavigate();


  const checkOpenModal = () => {
    if(mensagem.text){
      setOpenModal('open-modal')
    }
  }

  const handleFechar = () => {
    setOpenModal('modal-fechado')

    if(mensagem.text === 'Cadastro realizado com sucesso!'){
      navigate('/')
    }
  }

  useEffect( () => {
    checkOpenModal()
  }, [mensagem])


  return (
    <div className={style.modal} id={openModal}>
      <div className={style.modal__conteudo}>
        <span className={style.modal__fechar} onClick={handleFechar}>&times;</span>

        <div className={style.modal__status}>
          <span className={mensagem.status}></span>
        </div>
        <div className={style.modal__mensagem}>
          {mensagem.text}
        </div>
      </div>
    </div>
  )
}

export default Modal;