import { useState } from "react";

import style from "pages/Login/style.module.scss";
import API from "config/configs";
import Modal from "components/Modal";

const Cadastrar = () => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [mensagemModal, setMensagemModal] = useState({});
  const handleUsuario = (e) => setUsuario(e.target.value);
  const handleSenha = (e) => setSenha(e.target.value);
  const [verSenha, setVerSenha] = useState("password");
  const [verSenhaTitle, setVerSenhaTitle] = useState("Mostrar senha");

  const validaEmail = () => {
    var emailValid =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return emailValid.test(String(usuario).toLowerCase());
  };

  const validaSenha = () => {
    if (senha === "") {
      return false;
    }

    return true;
  };

  const realizarLogin = () => {
    let body = {
      usuario: usuario,
    };

    if (!validaEmail()) {
      setMensagemModal({
        text: "E-mail informado não é válido!",
        status: "erro",
      });
    } else if (!validaSenha()) {
      setMensagemModal({
        text: "Informe uma senha para o cadastro!",
        status: "erro",
      });
    }

    if (validaEmail() && validaSenha()) {
      // cadastra e realiza login
      fetch(`${API}/agendamentos/login`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: { "Content-type": "application/json; charset=UTF-8" },
        async: false,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.length) {
            setMensagemModal({
              text: "Usuário já possui cadastro!",
              status: "erro",
            });
          } else {
            // se não existe usuário cadastro no banco realiza cadastro:
            let body = {
              senha: `${senha}`,
              usuario: `${usuario}`,
            };

            fetch(`${API}/agendamentos/cadastrar`, {
              method: "POST",
              body: JSON.stringify(body),
              headers: { "Content-type": "application/json; charset=UTF-8" },
            })
              .then((response) => response.json())
              .then((json) => {
                let dadosSessaoLogado = JSON.stringify({
                  login: body.usuario,
                  senha: body.senha,
                });
                localStorage.setItem("isLogged", dadosSessaoLogado);

                setMensagemModal({
                  text: "Cadastro realizado com sucesso!",
                  status: "sucesso",
                });

                setSenha("");
                setUsuario("");
              })
              .catch((err) => {
                console.log(err);

                setMensagemModal({
                  text: "Erro de sistema, por favor entre em contato!",
                  status: "erro",
                });
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const mostrarSenha = () => {
    setVerSenha("text");

    if (verSenha === "text") {
      setVerSenha("password");
      setVerSenhaTitle("Mostrar senha");
    } else {
      setVerSenhaTitle("Esconder senha");
    }
  };

  return (
    <main className={style.contentLogin}>
      <Modal mensagem={mensagemModal} />
      <div className={style.login}>
        <h2 className={style.login__title}>Cadastro</h2>
        <label htmlFor="usuario" title="E-mail">
          E-mail:
        </label>
        <input
          type="email"
          id="usuario"
          placeholder="Digite seu e-mail..."
          className={style.login__field}
          value={usuario}
          onChange={handleUsuario}
        />

        <label htmlFor="senha" title="Senha do usuário">
          Cadastre sua Senha:
        </label>

        <div className={style.login__fieldSenha}>
          <div
            className={verSenha + " fieldLogin"}
            title={verSenhaTitle}
            onClick={mostrarSenha}
          ></div>
          <input
            type={verSenha}
            id="senha"
            placeholder="Digite sua senha..."
            className={style.login__field}
            value={senha}
            onChange={handleSenha}
          />
        </div>

        <a className="button" onClick={realizarLogin}>
          Cadastrar
        </a>
      </div>
    </main>
  );
};

export default Cadastrar;
