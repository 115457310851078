import { Link } from "react-router-dom";
import s from './style.module.scss';

const NotFound = () => {
  return (
    <main className={s.notfound}>
      <h1>Página não encontrada</h1>
      <p>Verifique se o link que está tentando acessar está correto.</p>
      <Link to={`/`}>Ir para página inicial</Link>
    </main >
  )
}

export default NotFound;