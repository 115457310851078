import API from 'config/configs';

export async function AgendamentosService(body) {
  const response = await fetch(`${API}/agendamentos/horarios`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });

  const json = await response.json();
  return json;
}

export async function CreateAgendamentoService(body) {
  const response = await fetch(`${API}/agendamentos/agendamento`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: { "Content-type": "application/json; charset=UTF-8" },
  });

  const json = await response.json();
  return json;
}