import { Route, Routes } from 'react-router-dom';
import Configuracoes from 'pages/Configuracoes';
import Cadastrar from 'pages/Cadastrar';
import Servicos from 'pages/Servicos';
import Funcionarios from 'pages/Funcionarios';
import NotFound from 'pages/NotFound';
import Agendar from 'pages/Agendar';
import Login from 'pages/Login';
import Agendamentos from 'pages/Agendamentos';

function Router() {

  return (
    <Routes>
      <Route index path="/" element={<Agendar />} />
      <Route path="/minhas-configuracoes" element={<Configuracoes />} />;
      <Route path="/meus-agendamentos" element={<Agendamentos />} />
      <Route path="/servicos" element={<Servicos />} />
      <Route path="/funcionarios" element={<Funcionarios />} />;
      <Route path="/login" element={<Login />} />
      <Route path="/cadastre-se" element={<Cadastrar />} />
      <Route path="/sair" element={<Agendar />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
