import styled from 'styled-components';

export const ServiceItem = styled.li`
  width: auto;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: .9375rem;
  font-weight: 500;
  margin: 0 16px 16px 0;

  &.selecionado{
    background-color: ${props => props.theme.primaryTheme1};
  }

  &:hover{
    background-color: ${props => props.theme.primaryTheme1};
    box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    color: #fff;
    cursor: pointer;
  }
`