import { FormLabel } from '@mui/material';
import s from './styles.module.scss'

export default function SelectHours({ showSelectHorarios, handleHorario, horarioLista, servicosSelecionados }) {
  
  return (
    <>
      {showSelectHorarios && (
          <>
            <FormLabel htmlFor="horario">
              Selecione um horário disponível:
            </FormLabel>
            <select
              name="horario"
              id="horario"
              className={s.agendar__field}
              onChange={handleHorario}
            >
              <option value="">Selecione um horário...</option>
              {horarioLista.map((item, index) => {
                let disabled = "";
                let status = "";

                if (
                  item.hora.indexOf("ausente") >= 0 ||
                  item.hora.indexOf("em atendimento") >= 0
                ) {
                  disabled = "disabled";
                }

                let hora = item.hora;
                let qtdMinutosItem = item.qtdMinutosItem;

                if (item.status === "em horário de almoço") {
                  status = " - em horário de almoço";
                  disabled = "disabled";
                }

                if (item.status === "indisponível") {
                  status = " - indisponível";
                  disabled = "disabled";
                }

                return (
                  <option
                    value={`[{"hora":"${hora}","qtdMinutosItem":"${qtdMinutosItem}"}]`}
                    key={index}
                    disabled={disabled}
                  >{`${item.hora}${status}`}</option>
                );
              })}
            </select>
          </>
      )}
      
      {!showSelectHorarios &&
      horarioLista.length === 0 &&
      servicosSelecionados.length !== 0 && (
        <>
          <p className={s.agendar__info}>
            Não há mais horários disponíveis para os serviços selecionados!
          </p>
        </>
      )}
    </>
  )
}