import styled from 'styled-components'

export const defaultTheme = {
  primaryTheme1: '#B22222',
  primaryTheme2: '#20B2AA',
  primaryTheme3: '#4682B4',
  primaryTheme4: '#000',
  primaryTheme5: '#FF69B4',
}

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.primaryTheme1}!important;
  padding: 0 12px;
  color: #fff;
  min-height: 38px;
  border-radius: 3px;
  border: 0;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, .3);
  text-transform: uppercase;
  font-size: .75rem;
  letter-spacing: 0.8px;
  cursor: pointer;

  &:hover {
    background: rgb(211 47 47)!important;
    border: 0;
    box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    color: #fff;
    box-shadow: none;
    border: 1px solid #111;
    font-weight: 500;
  }
`
  
export const Selected = styled.li`
  background-color: ${props => props.theme.primaryTheme1}!important;
  height: 38px;

  &:hover {
    background: rgb(211 47 47);
    border: 0;
    box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
  }
`
