import { useState } from "react";

import style from "pages/Login/style.module.scss";
import { Link, useNavigate } from "react-router-dom";

import API from "config/configs";
import Modal from "components/Modal";
import { Card, FormLabel } from "@mui/material";
import { loginService } from "services/login";

const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [senha, setSenha] = useState("");
  const [mensagemModal, setMensagemModal] = useState({});
  const handleUsuario = (e) => setUsuario(e.target.value);
  const handleSenha = (e) => setSenha(e.target.value);
  const [verSenha, setVerSenha] = useState("password");
  const [showLoader, setShowLoader] = useState(false);
  const [verSenhaTitle, setVerSenhaTitle] = useState("Mostrar senha");
  const navigate = useNavigate();

  const realizarLogin = () => {
    let body = {
      usuario: usuario,
      senha: senha,
    };

    let dadosSessaoLogado = JSON.stringify({ login: usuario, senha: senha });

    loginService(body)
      .then((data) => {
        if (data.length) {
          if (data[0].senha === senha) {
            localStorage.setItem("isLogged", dadosSessaoLogado);
            navigate("/");
          } else {
            setMensagemModal({
              text: "Login inválido, senha incorreta!",
              status: "erro",
            });
          }
        } else {
          setMensagemModal({
            text: "Login de usuário não encontrado, senha ou dados do usuário estão incorretos!",
            status: "erro",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mostrarSenha = () => {
    setVerSenha("text");

    if (verSenha === "text") {
      setVerSenha("password");
      setVerSenhaTitle("Mostrar senha");
    } else {
      setVerSenhaTitle("Esconder senha");
    }
  };

  const esqueciMinhaSenha = (e) => {
    e.preventDefault();
    setShowLoader(true);

    if (usuario !== "") {
      let body = {
        usuario: usuario,
      };

      loginService(body)
        .then((dados) => {
          if (dados.length) {
            let senha = dados[0].senha;
            let usuario = dados[0].usuario;

            let body = {
              email: usuario,
              subject: "Agendar Aqui - Recuperação de senha",
              html: ` 
                <strong>usuário:</strong> ${usuario} <br/>
                <strong>senha:</strong> ${senha}
              `,
            };

            fetch(`${API}/send-email`, {
              method: "POST",
              body: JSON.stringify(body),
              headers: { "Content-type": "application/json; charset=UTF-8" },
            })
              .then((response) => response.text())
              .then((text) => {
                if (text === "ok") {
                  setShowLoader(false);
                  setMensagemModal({
                    text: `Dados de login enviados com sucesso para o e-mail ${usuario}`,
                    status: "sucesso",
                  });
                }
              })
              .catch((err) => {
                console.log(
                  "Erro ao enviar e-mail de recuperação de senha",
                  err
                );
              });
          } else {
            setShowLoader(false);
            setMensagemModal({
              text: "Não existe usuário cadastrado com o e-mail informado, por favor realize o seu cadastro!",
              status: "erro",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setShowLoader(false);
      setMensagemModal({
        text: "Digite seu email para a recuperação de senha!",
        status: "erro",
      });
    }
  };

  return (
    <main className={style.contentLogin}>
      <Modal mensagem={mensagemModal} />
      {showLoader && (
        <div className="wrap-loader">
          <div class="loader"></div>
          <p>Recuperando seus dados...</p>
        </div>
      )}
      <Card className={style.login}>
        <h2 className={style.login__title}>Login</h2>
        <FormLabel htmlFor="usuario" title="E-mail">
          E-mail:
        </FormLabel>
        <input
          type="email"
          id="usuario"
          placeholder="Digite seu e-mail..."
          className={style.login__field}
          value={usuario}
          onChange={handleUsuario}
        />

        <FormLabel htmlFor="senha" title="Senha do usuário">
          Senha:
        </FormLabel>

        <div className={style.login__fieldSenha}>
          <div
            className={verSenha + " fieldLogin"}
            title={verSenhaTitle}
            onClick={mostrarSenha}
          ></div>
          <input
            type={verSenha}
            id="senha"
            placeholder="Digite sua senha..."
            className={style.login__field}
            value={senha}
            onChange={handleSenha}
          />
        </div>

        <a className="button" onClick={realizarLogin}>
          Entrar
        </a>
        <a className="button esqueci_senha" onClick={esqueciMinhaSenha}>
          Esqueci minha senha
        </a>
        <Link to={`/cadastre-se`} className={style.login__cadastrar}>
          Não tem cadastro? Faça seu cadastro aqui
        </Link>
      </Card>
    </main>
  );
};

export default Login;
